/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import background from "../images/artobg.png"


import ProfileTabs from "./profiletabs"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)



  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
      <div style={{ backgroundImage: `url("` + background + `")`  }}>
      <div className="container mx-auto">
        <main className="bg-white mt-10 rounded-t-md border  drop-shadow-lg pt-3">
          <div className="text-right text-red-800 font-bold">
          </div>
          <ProfileTabs></ProfileTabs>
          {children}
        
          </main>
        <footer className="text-right"
          style={{
            marginTop: `2rem`,
          }}
        >
          <span className="text-gray-900">Sidst opdateret { new Date().getFullYear().toString() }</span>
        </footer>
      </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
