import * as React from "react"
import { Link } from "gatsby"

const ProfileTabs = () => (
  <div>
    <ul className="flex flex-wrap text-sm text-center">
      <li className="ml-2 mr-2">
        <Link
          to="/"
          className="inline-block p-4 bg-tabs-inactive text-red-700 rounded-t-lg active"
          activeClassName="inline-block p-4 text-white bg-tabs rounded-t-lg active"
        >
          Profil
        </Link>
      </li>
      <li className="mr-2">
        <Link
          to="/guestbook"
          className="inline-block p-4 bg-tabs-inactive text-red-700 rounded-t-lg active"
          activeClassName="inline-block p-4 text-white bg-tabs rounded-t-lg active"
        >
          Gæstebog
        </Link>
      </li>
      <li className="mr-2">
        <Link
          to="/friendbook"
          className="inline-block p-4 bg-tabs-inactive text-red-700 rounded-t-lg active"
          activeClassName="inline-block p-4 text-white bg-tabs rounded-t-lg active"
        >
          Vennebog
        </Link>
      </li>

      <li className="mr-2">
        <Link
          to="/contact"
          className="inline-block p-4 bg-tabs-inactive text-red-700 rounded-t-lg active"
          activeClassName="inline-block p-4 text-white bg-tabs rounded-t-lg active"
        >
          Kontakt
        </Link>
      </li>
    </ul>
    <div className="bg-tabs-line h-2"></div>
  </div>
)

export default ProfileTabs
